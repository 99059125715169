.modal, .container {
  background: $white;
  border-radius: $content-border-radius;
  max-width: 90vw;
  max-height: 90vh;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: inline-block;
  position: absolute;
  h1 {
    margin-bottom: 10px;
  }
  input[type=text],
  input[type=email],
  input[type=date],
  input[type=password] {
    background: $off-white;
  }
}
.modal {
  padding: 50px 75px;
  h1, h3 {
    text-align: center;
  }
  h3 {
    margin-bottom: 20px;
  }

}
.container {
  padding: 40px 50px;
  width: 1000px;
}
.page {
  padding: 40px 50px;
  width: 100%;
  min-height: 100vh;
  background: $off-white url(/images/logo-gray.svg) right 40px top 30px no-repeat;
}