input, select, textarea {
  background: white;
  border-radius: $border-radius;
  border: none;
  font-size: 18px;
  font-family: "Source Sans Pro", sans-serif;
  padding-left: 20px;
  &:disabled {
    background: #e2e2e2;
  }

}
input, select {
  height: 52px;
}
textarea {
  padding: 15px 20px;
}
a.form-link {
  color: $dark-blue;
  display: block;
  &.login-link {
    margin-bottom: 40px;
  }
}
p.early-appt {
  text-align: left;

  input {
    float: left;
    margin-right: 25px;
  }
  &.loading {
    transition: $transition-time opacity;
    opacity: .5;
    pointer-events: none;
  }
  label, input {
    display: inline-block;
    vertical-align: middle;
  }
  input {
    float: none;
    height: auto;
  }
  label {
    width: calc(100% - 50px);
  }
}
.form, .search {
  .errors {
    display: block;
    position: relative;
    margin-bottom: 15px;
    background: $error;
    padding: 20px;
    color: $text;
    border-radius: $border-radius;
  }
}
.form .errors {
  width: 20vw;
}
.search .errors {
  width: 100%;
}
.form {
  h1 {
    margin-bottom: 60px;
    max-width: 60vw;
    max-width: 60vw;
  }
  h3 {
    margin-bottom: 20px;
    padding-top: 20px;
  }
  p, .paragraph {
    max-width: 60vw;
    margin-bottom: 1.4em;
    &.tip {
      background: $tip;
      padding: 20px;
      display: inline-block;
      border-radius: $border-radius;
      &.hide {
        display: none;
      }
    }
  }
  label, input, select {
    display: block;
  }
  .hide {
    display: none;
  }
  input[type=text], input[type=email], input[type=password], input[type=date], select, textarea {
    width: 510px;
    margin-bottom: 20px;
    &.error {
      background: $error;
      &:focus {
        background: $white;
      }
    }
  }
  input[type=radio],
  input[type=checkbox] {
    background: white;
    height: auto;
    padding-right: 12px;
  }
  label {
    margin-bottom: 8px;
    &.radio {
      padding-top: 10px;
      margin-bottom: 18px;
      &.medicaid-warning {
        background: #009ee8;
        color: white;
        padding: 20px;
        font-size: 18px;
      }
    }
  }
  .question-image {
    width: 50%;
    padding-bottom: 38%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: inline-block;
    height: 0;
  }
  .errors-wrapper {
    position: fixed;
    z-index: 1;
    right: 40px;
    top: 10vw;
  }
  .success {
    display: block;
    position: relative;
    margin-bottom: 15px;
    background: $success;
    padding: 20px;
    border-radius: $border-radius;
    width: 20vw;
  }
  .btn {
    margin-top: 20px;
    margin-right: 10px;
  }
  .optional {
    opacity: 0.5;
    font-style: italic;
  }
  .options {
    &.boxes {
      .option {
        width: 100%;
        background: #ddd;
        padding: 17px;
        margin-bottom: 10px;
        label {
          margin-bottom: 0;
        }
      }
    }
    max-width: 60vw;
    @extend .clearfix;
    margin-top: -10px;
    margin-left: -20px;
    padding-top: 10px;
    padding-left: 20px;
    margin-bottom: 20px;
    border-radius: $border-radius;
    &.error {
      background: $error;
    }
    .option {
      float: left;
      width: 50%;
      margin-bottom: 20px;
      input {
        width: 7%;
      }
      label {
        width: 93%;
      }
      input, label {
        display: inline-block;
        word-wrap: break-word;
        vertical-align: middle;
      }
    }
  }
}