@media screen and (max-width: 1000px) {
.form {  
  h1 {
    max-width: calc(100% - 200px);
    .optional {
      display: inline-block;
    }
  }
  .question-image {
    width: 100%;
    padding-bottom: 76%;
  }
  p, .paragraph {
    max-width: none;
  }
  input[type=text], input[type=email], input[type=password], input[type=date], select, textarea {
    width: 100%;
  }
  .errors, .success {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
  }
  .options {
    max-width: none;
    .option label {
      width: 80%;
    }

  //   .option {
  //     float: left;
  //     width: 50%;
  //     margin-bottom: 20px;
  //     input {
  //       width: 7%;
  //     }
  //     label {
  //       width: 93%;
  //     }
  //     input, label {
  //       display: inline-block;
  //       word-wrap: break-word;
  //       vertical-align: middle;
  //     }
  //   }
  }
}
}