@media screen and (max-width: 600px) {
.form {  
  background-position: center top 20px;
  h1 {
    margin-top: 70px;
    max-width: 100%;
    .optional {
      display: inline-block;
    }
  }
  .options .option {
    width: 100%;
  }
}
}