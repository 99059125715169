@import url('https://fonts.googleapis.com/css?family=Lato:400');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400i');

h1, h3 {
  font-weight: normal;
}
h1 {
  font-family: "Lato", sans-serif;
  font-size: 34px;
}
h3 {
  font-family: 18px;
  line-height: 26px;
}