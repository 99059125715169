* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: $transition-time color, $transition-time background-color;
}
html, body, #root {
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $dark-blue url(/images/logo-watermark.svg) left 30px bottom 30px no-repeat;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
}
a.btn, input.btn, button.btn {
  -webkit-appearance: none;
  height: 52px;
  background: $blue;
  color: $white;  
  display: inline-block;
  padding: 0 30px;
  line-height: 52px;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
  text-decoration: none;
  &.purple {
    background: $purple;
    &:hover {
      background: lighten($purple, 10%);
    }
  }
  &.orange {
    background: $orange;
    &:hover {
      background: lighten($orange, 10%);
    }
  }
  &.back:before {
    content: " ";
    width: 16px;
    height: 12px;
    background: url(/images/back.svg);
    display: inline-block;
    margin-right: 12px;
    top: 0px;
    position: relative;
  }
  &.search:before {
    content: " ";
    width: 13px;
    height: 12px;
    background: url(/images/search.svg);
    display: inline-block;
    margin-right: 12px;
    margin-top: 2px;
  }
  &.disabled {
    pointer-events: none;
    background: $dark-gray;
  }
  &:hover {
    background: $dark-blue;
  }
  &.default {
    background: $gray;
    color: $text;
  }
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

select {
  appearance: none;
  background: white url(/images/caret-down.svg) right 20px center no-repeat;
}
.debug {
  position: absolute;
  left: 50px;
  top: 50px;
  width: 50vw;
  line-break: break-word;
  word-break: break-word;
  color: white;
}

.clearfix {
  &:after {
    content: " ";
    width: 100%;
    display: block;
    clear: both;
    height: 0;
  }
}

.popover {
  padding: 12px;
}