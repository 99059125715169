@media screen and (max-width: 1000px) {
.date-range {
  position: relative;
  .react-datepicker-wrapper {
    width: calc(50% - 40px);
    input {
      min-width: none;
      width: 100%;
    }
  }
}
}