@media screen and (max-width: 600px) {
  .modal.search {
    top: 0;
    transform: none;
    left: 0;
    max-height: 100vh;
    min-width: 0;
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    padding: 50px 0px;
    &.confirm {
      padding-left: 20px;
      padding-right: 20px;
    }
    .btn.back {
      margin-top: 30px;
    }
    .results {
      padding: 0 30px;
    }
    .results .result {
      height: auto;
      text-align: center;
      margin-bottom: 20px;
      .btn {
        position: relative;
        margin-left: -20px;
        width: calc(100% + 40px);
        text-align: center;
        border-radius: 0 0 $border-radius $border-radius;
      }
    }
  }
.date-range {
  text-align: center;
  .react-datepicker-wrapper {
    width: 80%;
    display: block;
    margin: 20px auto;
    input {
      text-align: center;
      padding-left: 0;

    }
  }
}
}