$text: #444444;
$white: #fff;

$transition-time: 200ms;
$content-border-radius: 3px;
$border-radius: 2px;
$error: #fbc;

// user
$blue: #009ee8;
$dark-blue: #325a90;
$purple: #803281;
$black: #000;
$orange: #e55c00;
$off-white: #efefef; 
$gray: #cccccc;
$dark-gray: #878787;
$tip: #c5ddf7;
$success: $tip;

// admin
$admin-bg: #9A9DBD;
$admin-sidebar: #515151;
$admin-sidebar-active: #404040;
$admin-primary: #545399;
$admin-secondary: #995379;
$admin-highlight: #C8547B;
$admin-inactive: #BEBEBE;

$status-yellow: yellow;
$status-red: #dd0000;
