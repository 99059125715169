.modal.search {
  text-align: center;
  min-width: 515px;
  p.error {
    margin-top: -10px;
    margin-bottom: 45px;
  }
  .btn.back {
    margin-top: 50px;
  }
  &.confirm {
    h1 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 40px;
    }
  }
}
.btn.margin-top {
  margin-top: 10px;
  background: $dark-blue;
}
.btn.margin-bottom {
  margin-bottom: 10px;
}
.btn.margin-left  {
  margin-left: 10px;
  background: $dark-blue;
}

.results {
  overflow-y: scroll;
  max-height: calc(90vh - 300px);
  .result {
    background: $off-white;
    height: 52px;
    border-radius: $border-radius;
    width: 100%;
    margin-bottom: 12px;
    line-height: 52px;
    padding: 0 20px;
    text-align: left;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
    .btn {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.date-range {
  margin-top: 50px;
  margin-bottom: 60px;
  text-align: center;
  input, span {
    display: inline-block;
    vertical-align: middle;
  }
  span {
    margin: 0 20px;
  }
  input {
    min-width: 148px;
  }
}