.otp-option {
    background: #eee;
    padding: 20px;
    // border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    margin-bottom: 10px;
    display: block;
    &:hover {
        background: #ddd;
    }
    &.active {
        background: #cde6b1;
        // border-color: #94b56f;
    }
}
a.otp-resend {
    color: $dark-blue;
    display: block;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    margin-top: 10px;
    &.disabled {
        color: #8d8d8d;
        pointer-events: none;
    }
 
}
