.logout {
	position: absolute;
	z-index: 2;
	top: 10px;
	font-size: 14px;
	right: 50px;
	color: $admin-primary;
}
.menu {
	position: absolute;
  z-index: 2;
  left: 50px;
  top: 10px;
  font-size: 14px;
  a {
  	color: $admin-primary;
  	margin-right: 20px;
  }
}
.report .detail-area {
  border: 1px solid #ccc;
  .button-bar {
    background: #333;

  	button {
	  	padding: 10px;
	    border: none;
	    background: #333;
	    color: white;
	    border-radius: 0;
	    cursor: pointer;
	    &:hover {
	    	background: #555;
	    }
	  }
  }
}
.ProseMirror {
	background: white;
  padding: 20px;
  font-size: 16px;
  table {
  	border-collapse: collapse;
  	margin-bottom: 1em;
  	td {
  		border: 1px solid #ccc;
  		padding: 10px;
  	}
  }
  code {
	  font-weight: bold;
	  background-color: #faa;
	  padding: 4px;
  }
}
.filter-scs {
	padding-top: 15px;
	input, label {
		display:inline-block;
		vertical-align: top;

	}
}
table.space {
	margin: 12px 0;
	th, td {
		padding: 2px 7px;
		&:first-child {
			padding-left: 0;
		}
	}
	th {
		text-align: left;
	}
}
a.nav-page,
.pages span {
	font-size: 14px;
}
span.space {
	width: 20px;
	display: inline-block;
}
.notes {
	font-size: 14px;
	input[type=text]:not(.date-field) {
		width: 100%;
	}
}
p.large {
	font-size: 20px;
}
.tox-tinymce {
	height: calc(100vh - 200px) !important;
}
.admin .notes-field {
	width: 500px;
	input[type=text], textarea {
		width: 100%;
	}
}
.checkbox-list {
	input, label {
		display: inline-block;
	}
	input {
		margin-right: 5px;
	}
}
.pages span {
	padding-left: 5px;
	padding-right: 5px;
}
.pages span.current {
	background: lighten($admin-highlight, 30%);
}
.filter-high-priority {
	display: inline-block;
	padding: 0 20px;
	input {
		display: inline-block !important;
		margin-right: 5px;
	}
	span {
		font-size: 14px;
	}
}
a.nav-page {
	padding-right: 5px;
	padding-left: 5px;
	color: $admin-primary;
}
form.report-search-form {
	label, select {
		display: inline-block;
	}
	select {
		margin-right: 12px;
	}
	label {
		margin-right: 6px;
	  margin-left: 6px;
	  font-family: "Source Sans Pro", sans-serif;
	  font-size: 14px;
	}
}
.login-form {
	width: 500px;
	.error {
    background: #faa;
    padding: 15px;
    border-radius: $border-radius;
    display: block;
    margin-bottom: 5px;
	}
	form {
		input[type=password],
		input[type=email] {
			width: 100%;
			margin-bottom: 5px;
		}
	}
}
.fix {
	-webkit-overflow-scrolling: touch;
	body {
		overscroll-behavior: none;
	}
}
.admin {
	position: fixed;
}
.popover {
	max-height: 450px;
	overflow: auto;
	text-align: center;

	p {
		margin-bottom: 12px;
	}

	input {
		padding: 4px;
		height: 30px;
		border: 1px solid $gray;
	}

	.btn {
		margin-left: 8px;
		margin-right: 8px;

		&:disabled {
			background-color: lightgray;
			cursor: not-allowed;
			color: darkgray;
		}
	}
}

.detail-area-table {
	margin-bottom: 16px;
	border-collapse: collapse;

	th {
		padding: 4px 8px;
		font-size: 12px;
		border: 1px solid rgba(0, 0, 0, 0.2);
	}

	td {
		padding: 4px 8px;
		font-size: 12px;
		border: 1px solid rgba(0, 0, 0, 0.2);
	}
}
.admin, .popover {
	input:disabled {
		background:transparent;
		color: $text;
	}
	&.form h1 {
		margin-bottom: 10px;
	}
	.download-all-btn,
	.download-billing-btn {
		float: right;
		margin-bottom: 10px;
		margin-right: 0;
	}
	.download-billing-btn {
		margin-right: 10px;
	}
	
	span.left {
		height: 40px;
    float: left;
    line-height: 40px;
    display: inline-block;
    top: -15px;
    position: relative;
    .btn {
    	margin-left: 10px;
    }
	}
	.search-field {
		display: inline-block;
		margin-right: 5px;
    height: 30px;
    padding: 0px 5px;
    font-size: 16px;
    margin-top: 0px;
	}
	.btn {
		height: 30px;
		line-height: 30px;
		padding: 0 12px;
		font-size: 16px;
		&.small {
			height: 20px;
			line-height: 20px;
			padding: 0 10px;
			font-size: 12px;
			display: inline-block;
		}
		&.right {
			float: right;
			margin-bottom: 20px;
		}
		&.left {
			float: left;
			position: relative;
			top: -15px;
		}
		&.red {
			background: $status-red;
		}
	}
	.sync-therapists {
    margin-top: -10px;
    margin-bottom: 20px;
	}
	input[type=email], input[type=text], input[type=number], select, textarea {
		height: auto;
		width: auto;
		font-size: 12px;
		padding: 4px;
		min-width: 120px;
		margin-bottom: 4px;
		background:white;
	}
	input.btn {
		display: inline-block;
	}
	select {
		appearance: menulist;
	}
	select[multiple] {
		appearance: none;
		height: 100px;
	}
	&.detail {
		font-size: 12px;
		textarea {
			min-height: 5em;
		}
		h3 {
			margin-bottom: 0;
			padding-top: 10px;
			text-transform: uppercase;
		}
	}
	.user-form {
		input {
			width: 100%;
		}
	}
	select {
		width: auto;
		min-width: 150px;
	}
	td {
		select, input {
			min-width: 0;
		}
		&.narrow input {
			width: 50px;
		}
	}
	.download-link {
		margin-left: 10px;
	}
	.item {
		padding: 10px;
		&:nth-child(even) {
			background: #ccc;
		}
	}
	table.fullwidth {
		width: 100%;
		min-width: 100%;
		td, th {
			text-align: left;
			vertical-align: top;
			padding: 5px;
		}
	}
	table.fullwidth, table.fluid {
		td, th {
			text-align: left;
			vertical-align: top;
			padding: 5px;
		}
	}
	.editor {
		clear: both;
	}
	.detail-area, .ProseMirror {
		width: 100%;
		height: calc(100vh - 200px);
		overflow: scroll;
	}

	.results-area {
		width: 100%;
		margin-top: 0px;
		margin-bottom: 15px;
		height: calc(100vh - 310px);
		overflow: scroll;
		tbody {
			display: block;
			overflow: scroll;
			height: calc(100vh - 310px);
		}
		.header th {
			position: sticky;
			top: 0;
			background: $off-white;
		}

		table {
			width: 100%;
			border-collapse: collapse;
			tr {
				cursor: pointer;
			}
			tr:not(.header):hover {
				//background: $admin-primary;
				//color: white;
			}
			th {
				@extend .noselect;
			}
			th, td {
				font-size: 12px;
				transition: none;
				padding: 4px;
				border-right: 2px solid $off-white;
				&.sent {
					background: $status-yellow;
				}
				&.denied {
					background: $status-red;
				}
				&.sort {
					background: lighten($admin-highlight, 30%);
				}
				
			}
		}
	}
}